import * as React from 'react';
import Banner from '../../Section/Banner';
import Nod from '../../../images/nod.jpg';


export interface INotdienstProps {
}

export interface INotdienstState {
}

export default class Notdienst extends React.Component<INotdienstProps, INotdienstState> {
  constructor(props: INotdienstProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
             <Banner isHome={false} 
      pageName="Notdienst 24"
      color="violet"
      />
<div className="font-[sans-serif] text-gray-800 bg-gray-50 px-6 py-12 m-6 shadow-lg">
  <div className="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
    <div className="text-left">
      <h2 className="text-4xl font-extrabold mb-6">Willkommen auf unserer Webseite</h2>
      <p className="mb-4 text-sm">Wir sind Ihr verlässlicher Partner für Installationen in Wien und Niederösterreich. Unser Service umfasst Wasser, Abwasser, Gas, Kälteanlagen und Sanitärinstallationen – alles aus einer Hand und nicht aufgeteilt.</p>
      <p className="mb-4 text-sm">Egal, ob es um die Installation von Sanitäranlagen, die Einrichtung von Gasleitungen oder die Installation von Kälteanlagen geht, unser erfahrenes Team sorgt für eine reibungslose und effiziente Umsetzung.</p>
      <p className="text-sm">Kontaktieren Sie uns für maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind – immer mit dem höchsten Anspruch an Qualität und Zuverlässigkeit.</p>
      <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-8 mt-12">
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Installationen</h6>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Wasser & Abwasser</h6>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Gas & Kälteanlagen</h6>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Sanitäranlagen</h6>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Qualität und Zuverlässigkeit</h6>
        </div>
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
            <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
          </svg>
          <h6 className="text-base font-semibold ml-4">Service für Wien & Niederösterreich</h6>
        </div>
      </div>
    </div>
    <div className="flex justify-center items-center">
      <img src={Nod} alt="Platzhalterbild" className="rounded-lg object-cover w-full h-full shadow-lg" />
    </div>
  </div>
</div>

{/* Feature Section */}
<div>
    <h1 className="text-4xl font-extrabold text-center mb-12">Wie Sie den Service anfordern</h1>
</div>
<div className="font-[sans-serif] w-max my-4 items-center mx-auto">
    <div className="flex items-center flex-col">
        <div
        className="w-6 h-6 shrink-0 mx-[-1px] ring-2 ring-gray-300 ring-offset-4 bg-green-500 p-1 flex items-center justify-center rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-full fill-white" viewBox="0 0 24 24">
            <path
            d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
            data-original="#000000" />
        </svg>
        </div>
        <div className="my-4 text-center max-w-[200px]">
        <h6 className="text-base font-bold text-[#333]">Anfrage stellen</h6>
        <p className="text-sm text-gray-500 mt-1">Kontaktieren Sie uns über das Formular oder per Telefon.</p>
        </div>
        <div className="w-1 h-24 bg-gray-300 rounded-xl"></div>
    </div>

    <div className="flex items-center flex-col">
        <div
        className="w-6 h-6 shrink-0 mx-[-1px] ring-2 ring-gray-300 ring-offset-4 p-1 flex items-center justify-center rounded-full">
        <span className="w-6 h-6 bg-blue-500 rounded-full shrink-0"></span>
        </div>
        <div className="my-4 text-center max-w-[200px]">
        <h6 className="text-base font-bold text-[#333]">Beratungsgespräch</h6>
        <p className="text-sm text-gray-500 mt-1">Unsere Experten beraten Sie und erstellen ein individuelles Angebot.</p>
        </div>
        <div className="w-1 h-24 bg-gray-300 rounded-xl"></div>
    </div>

    <div className="flex items-center flex-col">
        <div
        className="w-6 h-6 shrink-0 mx-[-1px] ring-2 ring-gray-300 ring-offset-4 p-1 flex items-center justify-center rounded-full">
        <span className="text-base text-gray-500 font-bold">3</span>
        </div>
        <div className="my-4 text-center max-w-[200px]">
        <h6 className="text-base font-bold text-[#333]">Angebot bestätigen</h6>
        <p className="text-sm text-gray-500 mt-1">Bestätigen Sie das Angebot, um den Service zu buchen.</p>
        </div>
        <div className="w-1 h-24 bg-gray-300 rounded-xl"></div>
    </div>

    <div className="flex items-center flex-col">
        <div
        className="w-6 h-6 shrink-0 mx-[-1px] ring-2 ring-gray-300 ring-offset-4 p-1 flex items-center justify-center rounded-full">
        <span className="text-base text-gray-500 font-bold">4</span>
        </div>
        <div className="my-4 text-center max-w-[200px]">
        <h6 className="text-base font-bold text-[#333]">Terminvereinbarung</h6>
        <p className="text-sm text-gray-500 mt-1">Vereinbaren Sie einen Termin für die Installation.</p>
        </div>
    </div>
</div>

<div className="bg-gradient-to-b from-blue-900 via-blue-800 to-blue-500 px-6 sm:py-20 py-10 font-[sans-serif]">
      <div className="max-w-screen-xl mx-auto text-center text-white">
        <h1 className="text-5xl max-sm:text-3xl font-extrabold leading-tight mb-6">Haben Sie noch Fragen?</h1>
        <p className="text-lg mb-12">
        Wir sind für Sie da. Der Notdienst ist 24/7 erreichbar
        und hilft Ihnen bei allen Fragen rund um Installationen.

        </p>
        <button
        onClick={() => {
            window.location.href = "tel:+436769212103";
        }
        }
         type="button" className="bg-blue-600 text-white text-lg tracking-wide px-8 py-2.5 rounded-full transition duration-300 ease-in-out shadow-lg hover:shadow-xl">
            Rufen Sie uns an
        </button>
      </div>
    </div>


    <div className="bg-gray-100 p-8 font-[sans-serif]">
    <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b border-gray-300">
            <h2 className="text-3xl font-extrabold text-indigo-800">Häufig gestellte Fragen</h2>
            <p className="text-gray-600 mt-4 text-sm">Entdecken Sie unsere umfassenden FAQ, um Antworten auf häufige Fragen zu finden.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-6">
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Welche Notfälle deckt der Installateur Notdienst ab?</h3>
                <p className="text-gray-600 text-sm">Unser Notdienst deckt alle Arten von Sanitär-, Heizungs- und Rohrbruch-Notfällen ab, die sofortige Aufmerksamkeit erfordern.</p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Wie schnell ist der Installateur Notdienst vor Ort?</h3>
                <p className="text-gray-600 text-sm">In der Regel sind wir innerhalb von 30 bis 60 Minuten in Wien und Niederösterreich bei Ihnen vor Ort.</p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Ist der Notdienst rund um die Uhr verfügbar?</h3>
                <p className="text-gray-600 text-sm">Ja, unser Installateur Notdienst steht Ihnen 24 Stunden am Tag, 7 Tage die Woche zur Verfügung.</p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Welche Kosten entstehen für den Notdienst?</h3>
                <p className="text-gray-600 text-sm">Die Kosten hängen vom Umfang des Notfalls ab. Wir bieten transparente Preisgestaltung, die vor Ort besprochen wird.</p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Kann ich den Notdienst auch für kleinere Probleme rufen?</h3>
                <p className="text-gray-600 text-sm">Ja, unser Notdienst steht Ihnen auch bei kleineren Problemen wie verstopften Abflüssen oder tropfenden Wasserhähnen zur Verfügung.</p>
            </div>
            <div className="bg-indigo-50 p-6 rounded-lg border border-indigo-200">
                <h3 className="text-lg font-semibold text-indigo-700 mb-2">Wie kann ich den Installateur Notdienst kontaktieren?</h3>
                <p className="text-gray-600 text-sm">Sie können uns jederzeit telefonisch erreichen oder unser Online-Formular für eine schnelle Reaktion ausfüllen.</p>
            </div>
        </div>
    </div>
</div>


      </div>
    );
  }
}
