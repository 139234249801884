import * as React from 'react';

export interface IFeatureHighlightsSectionProps {
    title: string;
    description: string;
    features: string[];
    imgSrc: any;
}

export interface IFeatureHighlightsSectionState {
}

export default class FeatureHighlightsSection extends React.Component<IFeatureHighlightsSectionProps, IFeatureHighlightsSectionState> {
  constructor(props: IFeatureHighlightsSectionProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <div className="max-w-7xl max-md:max-w-xl mx-auto font-[sans-serif] text-[#333]">
      <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-12 items-center">
        <div>
          <img src={this.props.imgSrc} className="w-full rounded-md" />
        </div>
        <div className="xl:col-span-2">
          <div>
            <h2 className="sm:text-3xl text-2xl font-extrabold">{this.props.title}</h2>
            <p className="text-sm text-gray-500 mt-6">{this.props.description}</p>
          </div>
          <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-8 mt-12">

            {
                this.props.features.map((feature, index) => (
                    <div className="flex items-center" key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" className="fill-green-500 shrink-0" viewBox="0 0 24 24">
                        <path d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z" data-original="#000000"></path>
                    </svg>
                    <h6 className="text-base font-semibold ml-4">{feature}</h6>
                    </div>
                ))
            }

          </div>
        </div>
      </div>
    </div>
      </div>
    );
  }
}
{/*
<FeatureHighlightsSection
title="Lorem ipsum dolor sit amet"
description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet accumsan arcu. Sed sit amet accumsan arcu."
features={[
    "Lorem ipsum dolor sit amet",
    
    
]}
/>
*/}
