import * as React from "react";
import Banner from "../Section/Banner";
import { NavLink } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.css';
import ServicesSection from "./ServicesSection";
import Worksteps from "../Section/Worksteps";
import Cookiescomponent from "../Section/Cookiescomponent";
import CarouselTestimonial from "../Section/CarouselTestimonial";
import CardImage from "../Section/CardImage";
import FeatureHighlightsSection from "../Section/Features/FeatureHighlightsSection";
import FeatureSectionColorDesign from "../Section/Features/FeatureSectionColorDesign";
import Einsatzgebiete from "../24Notdienst/Einsatzgebiete";
import LogoImage from '../../images/logo.png';
import HomeHero from '../../images/homeHero.png';
import Sanitaer from '../../images/Sanitaer.jpg';
import Heating from '../../images/Heating.jpg';
import ThermenwartungImage from '../../images/Thermenwartung.jpg';
import NotdienstImage from '../../images/service1.jpg';
import calenderImage from '../../images/calender2024.jpg';
import WärmepumpenrevolutionImage from '../../images/Wärmepumpenrevolution.jpg';




export interface IHomeProps {}

export interface IHomeState {}

export default class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);

    this.state = {};
  }
  scrollToSecondSection = () => {
    const secondSection = document.querySelector(".second-section");
    if (secondSection) {
      secondSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  public render() {
    return (
      <div className="">
        <div
          className={`relative z-10 overflow-hidden pt-[80px] md:pt-[130px] lg:pt-[90px] bg-white`} >
          {/* <!-- ====== Hero Section Start -->*/}
          <div
            id="home"
            className="relative overflow-hidden bg-gradient-to-r from-[#00ceef] to-gray-900 pt-[120px] md:pt-[130px]"
          >
            <div className="container">
              <div className="-mx-4 flex flex-wrap items-center">
                <div className="w-full px-4">
                  <div
                    className="hero-content wow fadeInUp mx-auto  lg:w-full text-center"
                    data-wow-delay=".2s"
                  >
                    <h1 className="text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2] ">
                      WILLKOMMEN BEI 
                    </h1>
                    <h1 className="mb-6 text-3xl font-bold leading-snug text-[#fec834] sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2] ">
                    Installateur  Mannus
                    </h1>
                    <p className="mx-auto font-medium text-base text-white lg:text-2xl sm:text-lg sm:leading-[1.44]">
                      Ihr Installateur in Wien und Umgebung
                    </p>
                    {/**     <ul className="mb-10 flex gap-5 flex-wrap items-center justify-center">
              <li>
                <a href="https://links.tailgrids.com/play-download"
                  className="inline-flex items-center justify-center rounded-md bg-white py-[14px] px-7 text-center text-base font-medium text-dark shadow-1 transition duration-300 ease-in-out hover:text-body-color hover:bg-gray-2">
                  Download Now
                </a>
              </li>
              <li>
                <a href="https://github.com/tailgrids/play-tailwind" target="_blank"
                  className="flex items-center gap-4 py-[14px] rounded-md bg-white/[0.12] px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-white hover:text-dark">
                  <svg className="fill-current" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2005_10818)">
                      <path
                        d="M12 0.674805C5.625 0.674805 0.375 5.8498 0.375 12.2998C0.375 17.3998 3.7125 21.7498 8.3625 23.3248C8.9625 23.4373 9.15 23.0623 9.15 22.7998C9.15 22.5373 9.15 21.7873 9.1125 20.7748C5.8875 21.5248 5.2125 19.1998 5.2125 19.1998C4.6875 17.8873 3.9 17.5123 3.9 17.5123C2.85 16.7623 3.9375 16.7623 3.9375 16.7623C5.1 16.7998 5.7375 17.9623 5.7375 17.9623C6.75 19.7623 8.475 19.2373 9.1125 18.8998C9.225 18.1498 9.525 17.6248 9.8625 17.3248C7.3125 17.0623 4.575 16.0498 4.575 11.6248C4.575 10.3498 5.0625 9.3373 5.775 8.5498C5.6625 8.2873 5.25 7.0873 5.8875 5.4748C5.8875 5.4748 6.9 5.1748 9.1125 6.6748C10.05 6.4123 11.025 6.2623 12.0375 6.2623C13.05 6.2623 14.0625 6.3748 14.9625 6.6748C17.175 5.2123 18.15 5.4748 18.15 5.4748C18.7875 7.0498 18.4125 8.2873 18.2625 8.5498C19.0125 9.3373 19.4625 10.3873 19.4625 11.6248C19.4625 16.0498 16.725 17.0623 14.175 17.3248C14.5875 17.6998 14.9625 18.4498 14.9625 19.4998C14.9625 21.0748 14.925 22.3123 14.925 22.6873C14.925 22.9873 15.15 23.3248 15.7125 23.2123C20.2875 21.6748 23.625 17.3623 23.625 12.2248C23.5875 5.8498 18.375 0.674805 12 0.674805Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2005_10818">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Star on Github
                </a>
              </li>
            </ul>
            <div className="wow fadeInUp text-center" data-wow-delay=".3s">
              <img src="../../../img/home/brand.svg" alt="image"
                className="mx-auto w-full max-w-[250px] opacity-50 transition duration-300 ease-in-out hover:opacity-100" />
            </div> */}
                  </div>
                </div>

                <div className="w-full px-4">
                  <div
                    className="wow fadeInUp relative z-10 mx-auto max-w-[650px]"
                    data-wow-delay=".25s"
                  >
                    <div className="mt-16">
                      <img
                        src={HomeHero}
                        alt="hero "
                        className="mx-auto  rounded-t-xl rounded-tr-xl"
                      />
                    </div>
                    <div className="absolute bottom-0 -left-9 z-[-1]">
                      <svg
                        width="134"
                        height="106"
                        viewBox="0 0 134 106"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="1.66667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 1.66667 104)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 16.3333 104)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 31 104)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 45.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 60.3333 104)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 88.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 117.667 104)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 74.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 103 104)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 132 104)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 31 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 103 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 132 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="74.6673"
                          r="1.66667"
                          transform="rotate(-90 1.66667 74.6673)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 16.3333 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 31 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 31 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 45.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 60.3333 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 60.3333 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 88.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 88.6667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 117.667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 117.667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 74.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 74.6667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 103 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 103 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 132 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 132 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 1.66667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 16.3333 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 31 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 31 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 45.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 60.3333 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 60.3333 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 88.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 88.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 117.667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 117.667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 74.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 74.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 103 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 103 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 132 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 132 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 1.66667 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 1.66667 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 16.3333 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 16.3333 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 31 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 31 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 45.6667 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 45.6667 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 60.3333 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 88.6667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 117.667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 74.6667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 103 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 103 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 132 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 132 1.66707)"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="absolute -top-6 -right-6 z-[-1]">
                      <svg
                        width="134"
                        height="106"
                        viewBox="0 0 134 106"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="1.66667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 1.66667 104)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 16.3333 104)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 31 104)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 45.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 60.3333 104)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 88.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 117.667 104)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 74.6667 104)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 103 104)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 132 104)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 31 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 89.3333)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 103 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 132 89.3338)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="74.6673"
                          r="1.66667"
                          transform="rotate(-90 1.66667 74.6673)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 16.3333 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 31 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 31 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 45.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 31.0003)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 60.3333 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 60.3333 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 88.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 88.6667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 117.667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 117.667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 74.6667 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 74.6667 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 103 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 103 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 132 74.6668)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="31.0001"
                          r="1.66667"
                          transform="rotate(-90 132 31.0001)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 1.66667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 16.3333 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 31 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 31 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 45.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 60.3333 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 60.3333 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 88.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 88.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 117.667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 117.667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 74.6667 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 74.6667 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 103 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 103 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 132 60.0003)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="16.3336"
                          r="1.66667"
                          transform="rotate(-90 132 16.3336)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 1.66667 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="1.66667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 1.66667 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 16.3333 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="16.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 16.3333 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 31 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="31"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 31 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="45.3336"
                          r="1.66667"
                          transform="rotate(-90 45.6667 45.3336)"
                          fill="white"
                        />
                        <circle
                          cx="45.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 45.6667 1.66683)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="60.3333"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 60.3333 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="88.6667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 88.6667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="117.667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 117.667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="74.6667"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 74.6667 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 103 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="103"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 103 1.66707)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 132 45.3338)"
                          fill="white"
                        />
                        <circle
                          cx="132"
                          cy="1.66707"
                          r="1.66667"
                          transform="rotate(-90 132 1.66707)"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0">
                <img src="../../../img/home/hero/bg-pattern.png" alt="bg-pattern" className="block dark:hidden "/>
            </div>
          </div>
          {/*  <!-- ====== Hero Section End -->*/}

          {/*  <!-- ====== Features Section Start --> */}
          
      <section>
        <h4 className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100 my-3">Unsere Dienstleistungen</h4>
        <p className="text-center text-gray-600 dark:text-gray-300">Wir bieten eine breite Palette von Dienstleistungen an, die auf Ihre individuellen Anforderungen zugeschnitten sind.</p>
        <div className="container px-6 m-auto my-6">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
        <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Wasser & Sanitär"
              isWithimg={true}
              img={Sanitaer}
      />
        </div>
            <div className="col-span-4 lg:col-span-3">
              <CardImage 
              title="Heizung & Klima"
              isWithimg={true}
              img={Heating}
      />
      </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Thermenwartung"
              isWithimg={true}
              img={ThermenwartungImage}
      />
            </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Notdienst 24"
              isWithimg={true}
              img={LogoImage}
      />
            </div>
          </div>

          <div className=" justify-center mt-6 mb-12 lg:mb-0 lg:my-12 w-full">
            <NavLink to="/services">
              <button
                className="w-full rounded-md bg-blue-600 bg-opacity-100 py-6 px-6 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
              >
                Kostenloses Angebot
              </button>
            </NavLink>
          </div>
        </div>

      </section>
{
/*  <!-- ====== wasser
      <section  className="pt-20 pb-8 lg:pt-[120px] lg:pb-[70px] ">
            <div className="container">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                  <div className="mb-12 mx-auto max-w-[485px] lg:mb-[70px] text-center">
                    <span className="mb-2 block text-lg font-semibold text-primary">
                    Dienstleistungen
                    </span>
                    <h2 className="mb-3 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] md:leading-[1.2]">
                      Wir bieten beste Dienstleistungen
                    </h2>
                    <p className="text-base text-body-color">
                    Unsere Dienstleistungen zeichnen sich durch höchste Qualität und Fachkompetenz aus, um sicherzustellen, dass Ihre Anforderungen in Wien und darüber hinaus optimal erfüllt werden.
                    </p>
                  </div>
                </div>
              </div>
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12 mb-12">
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
          </div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
                </div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
                </div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div></div>
          </div>
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div></div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div></div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
            <div className="col-span-4 lg:col-span-3">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
          </div>
         
            </div>
          </section>
           --> */}
          
          {/*  <!-- ====== Features Section End --> */}
          <div className=" flex flex-col justify-center items-center bg-white">
            {/* First Section */}

            {/* Down Arrow */}
            <div className="mt-8 ">
              <svg
                onClick={this.scrollToSecondSection}
                className="animate-bounce w-6 h-6 m-auto cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M12 21l-8-8m8 8l8-8m-8 8V3"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>

          {/* Second Section (Initially Hidden) */}
          
          <div className="second-section justify-center items-center flex flex-wrap bg-gradient-to-r from-cyan-500 to-blue-700">
            {/* Left Section (Large Font Text) */}
            
     
 
          </div>

          <div className="second-section justify-center items-center flex flex-wrap  bg-radial-gradient-2 shadow-lg">
            {/* Left Section (Large Font Text) */}
            <NavLink to="/services">
            <div className="w-full flex">
              <button className="bg-primary-700 text-black w-96 font-bold uppercase text-lg px-4 rounded-full m-auto py-3 my-10 justify-center items-center bg-white">
              Kostenloses Angebot
                {/** svg right Arrow */}
                <svg
                  className="inline w-6 h-6 ml-2 m-auto justify-center items-center"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
            </NavLink>

            <div className="flex flex-col md:flex-row w-full pb-20 items-stretch">
              {/* Left Section (Image) */}
              <div className="m-auto w-96">
                <img
                  src={LogoImage}
                  className=" h-auto m-auto"
                  alt="iPhone 11"
                />
              </div>

              {/* Right Section (Large Font Text) */}
              <div className="w-full md:w-1/2 p-4 m-auto ">
                <h1 className="text-2xl m-auto pb-10 font-bold text-center text-black ">
                Installateur  Mannus - Ihr Installateur in Wien und Niederösterreich
                </h1>

                <h1 className="text-black font-bold text-center Lg:w-1/2 m-auto ">
                Wir sind Ihr zuverlässiger Partner für Gas- und Wasserinstallationen in Wien und Niederösterreich. Unser erfahrenes Team bietet Ihnen professionelle Dienstleistungen zu fairen Preisen. Kontaktieren Sie uns für eine kostenlose Beratung und ein unverbindliches Angebot.
                </h1>
              </div>
            </div>
          </div>
          
        </div>

        {/* Third Section */}

<div className="container mx-auto px-4 py-4 bg-white shadow-lg rounded-lg my-10">
<FeatureHighlightsSection
imgSrc={NotdienstImage}
title="Warum Sie sich im Notfall auf Uns verlassen können?"
description="Die Firma Notdienst bietet Ihnen einen 24-Stunden-Service an 365 Tagen im Jahr. Wir sind immer für Sie da, wenn Sie uns brauchen. Unsere erfahrenen Installateure sind schnell vor Ort und beheben Ihr Problem zuverlässig und professionell. Wir sind Ihr zuverlässiger Partner in Sachen Gas- und Wasserinstallationen."
features={[
    "Individuelle Beratung",
    "Schnelle Erreichbarkeit",
    "Erfahrene Installateure",
    "Faire Preise",
    "Gewährleistung & Garantie",
    "Kostenlose Beratung",       
    "Kostenlose Angebotserstellung",
    "Kostenlose Vor-Ort-Besichtigung",
    "Kostenlose Planung",]}
/>  
</div>
{/**Slide section  start*/}
<h1 className="text-xl lg:text-4xl font-bold text-center text-black py-10">
          Warum Uns Wählen?
          </h1>
<ServicesSection 
services={[
  {
    title: "Heizungsinstallation",
    description:
      " Mannus installiert effiziente und moderne Heizsysteme, die Ihr Zuhause in den kalten Monaten warm halten. Wir verwenden nur hochwertige Komponenten und garantieren eine fachgerechte Installation für maximale Leistung und Langlebigkeit.",
    imageSrc: {LogoImage},  
    company: "Durchschnittliche Installationszeit: 8 Stunden",
  },
  {
    title: "Sanitärinstallation",
    description:
      "Unsere Sanitärinstallationen sorgen für reibungslose Abläufe in Ihrem Badezimmer und Ihrer Küche. Von der Installation neuer Armaturen bis hin zur kompletten Sanierung bieten wir umfassende Dienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind.",
      imageSrc: {LogoImage},  
      company: "Garantie: 5 Jahre",
  },
  {
    title: "Rohrreinigung",
    description:
      "Verstopfte Rohre können zu erheblichen Problemen führen.  Mannus bietet professionelle Rohrreinigungsdienste an, die schnell und effektiv verstopfte Leitungen wieder frei machen. Wir verwenden moderne Techniken und Werkzeuge, um Ihre Rohrleitungen zu reinigen und zukünftige Probleme zu vermeiden.",
      imageSrc: {LogoImage},  
      company: "Technik: Hochdruckreinigung",
  },

  {
    title: "Thermenwartung",
    description:
      "Eine regelmäßige Wartung Ihrer Therme ist entscheidend für deren Effizienz und Lebensdauer.  Mannus bietet umfassende Wartungsdienste an, die sicherstellen, dass Ihre Heizungsanlage stets optimal funktioniert.",
      imageSrc: {LogoImage},  
      company: "Serviceintervall: Jährlich",
  },
]}

      />
      {/**Slide section  end*/}
        {/* Fourth Section */}
        
        <section className="pb-20 bg-radial-gradient-2">

        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i className="fas fa-award"></i>

                  </div>
                  <h6 className="text-xl font-semibold">Installateur Notdienst 24/7: Soforthilfe in Wien und Niederösterreich</h6>
                  <p className="mt-2 mb-4 text-gray-600">In akuten Situationen sind wir rund um die Uhr für Sie da. Erfahren Sie mehr über unseren Installateur Notdienst und wie wir bei Rohrbrüchen, Wasserschäden, Gaslecks und Abflussverstopfungen schnell und zuverlässig Hilfe bieten. </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400"
                  >
                    <i className="fas fa-retweet "></i>
                  </div>
                  <h6 className="text-xl font-semibold">Ihr Installateur in Wien: Mannus</h6>
                  <p className="mt-2 lg:mb-20 mb-3 text-gray-600">Verlassen Sie sich auf unsere Fachkenntnisse und unser Engagement, um Ihnen in kritischen Momenten beizustehen und eine sorgenfreie Wohnatmosphäre zu garantieren. </p>
                </div>
              </div>
            </div>
            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400"
                  >
                    <i className="fas fa-fingerprint"></i>
                  </div>
                  <h6 className="text-xl font-semibold">Notdienst Installateur in Wien: Mannus – Schnelle Hilfe bei Rohrbruch, Heizungsausfall & Co.</h6>
                  <p className="mt-2 mb-4 text-gray-600">Der Mannus Notdienst in Wien steht bei akuten Schäden an Gasleitungen, Wasserleitungen, Heizungsanlagen und Sanitärobjekten rund um die Uhr zur Verfügung. Unsere erfahrenen Installateure sind an 365 Tagen im Jahr innerhalb von 30 Minuten vor Ort, um zuverlässig und schnell alle Notfälle zu beheben.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center mt-32">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i className="fas fa-user-friends text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
              Warum Mannus Installateur Wien?
              Professionelle Installationen für Ihr Zuhause
              </h3>
              <p
                className="text-lg font-light leading-relaxed mt-4 mb-4 text-white">Ihre Profi Installateur in Wien! Mit 20 Jahren Erfahrung garantieren wir nicht nur fachgerechte Installationen, sondern auch umfassende Kenntnisse im Umgang mit Gasanlagen. Vertrauen Sie auf unsere Expertise für sichere und effiziente Gasinstallationen. Verlassen Sie sich auf unsere Kompetenz in allen Bereichen der Installationstechnik. Kontaktieren Sie uns für maßgeschneiderte Lösungen in Wien. Wir freuen uns darauf, Ihnen zu helfen! </p>

            </div>
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg "
              >
                <img
                  alt="..."
                  src={calenderImage}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-[95px] -top-[94px]"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-[#0ea5f9] fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-black">
                  Gratis Angebot / Kostenvoranschlag
                  </h4>
                  <p className="text-md font-light mt-2 text-black">Wir geben uns Mühe Ihnen die Angebots-Anfrage so einfach wie möglich zu machen, deshalb bieten wir viele Möglichkeiten an wie Sie schnell und kostenlos zu einem persönlichen Angebot kommen.</p>
                  <div className="flex flex-col items-center mt-8">
                  <NavLink to="/services">
                      <button className=" rounded-md bg-blue-600 bg-opacity-100 py-2 px-6 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white">
                        Kostenloses Angebot
                      </button>
                  </NavLink>
                  </div>
                </blockquote>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      

     <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-[80px]"
            
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className=" rounded-lg shadow-lg"
                  src={WärmepumpenrevolutionImage}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-xl lg:text-3xl font-semibold">Wärmepumpenrevolution 2024</h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  Sichern Sie sich Ihr persönliches Wärmepumpen-Komplettpaket – Wärmepumpen Förderung mit Mannus GmbH
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                          Reduzieren Sie Ihre CO₂-Emissionen um über 75%
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                          Befreien Sie sich von Öl- und Gasabhängigkeit
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Senken Sie Ihre jährlichen Heizkosten um mindestens 25% </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Profitieren Sie von bis zu 100% Förderung Ihrer Anschaffungskosten </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Steigern Sie den Wert Ihrer Immobilie auf dem Markt </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
        </section>


    <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 pb-20">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-sky-500">
        <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clipRule="evenodd"></path>
        <path fillRule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clipRule="evenodd"></path>
      </svg>
      <div className="space-y-6 justify-between text-gray-600 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
        <div className="md:5/12 lg:w-1/2 relative">
        <svg xmlns="http://www.w3.org/2000/svg"  height="402" viewBox="0 0 456 402" fill="none" className="w-[100%]">
<path d="M262.385 109.641H260.385V32.5977H405.042V34.5977H262.385V109.641Z" fill="#3F3D56"/>
<path d="M427.225 55.56C442.568 55.56 455.005 43.1225 455.005 27.78C455.005 12.4375 442.568 0 427.225 0C411.883 0 399.445 12.4375 399.445 27.78C399.445 43.1225 411.883 55.56 427.225 55.56Z" fill="#01FEE6"/>
<path d="M423.435 42.2376C422.956 42.2376 422.482 42.1259 422.053 41.9113C421.624 41.6967 421.251 41.3851 420.963 41.0013L413.385 30.896C413.141 30.5714 412.964 30.202 412.863 29.8089C412.763 29.4158 412.74 29.0067 412.798 28.605C412.855 28.2033 412.991 27.8168 413.198 27.4676C413.405 27.1184 413.678 26.8134 414.003 26.5699C414.327 26.3265 414.697 26.1493 415.09 26.0486C415.483 25.9479 415.892 25.9257 416.294 25.9831C416.696 26.0405 417.082 26.1764 417.431 26.3832C417.78 26.59 418.085 26.8635 418.329 27.1881L423.287 33.7987L436.022 14.6967C436.247 14.3587 436.536 14.0683 436.873 13.8421C437.211 13.6159 437.589 13.4585 437.987 13.3786C438.385 13.2988 438.795 13.2983 439.194 13.377C439.592 13.4557 439.971 13.6121 440.309 13.8374C440.647 14.0626 440.937 14.3522 441.163 14.6896C441.389 15.0271 441.546 15.4057 441.625 15.8039C441.705 16.2021 441.705 16.612 441.626 17.0103C441.547 17.4086 441.39 17.7874 441.164 18.125L426.007 40.8615C425.733 41.2726 425.364 41.6121 424.931 41.8514C424.499 42.0906 424.015 42.2227 423.521 42.2366C423.493 42.2371 423.464 42.2376 423.435 42.2376Z" fill="white"/>
<path d="M164.385 296.686H162.385V373.729H307.042V371.729H164.385V296.686Z" fill="#3F3D56"/>
<path d="M329.225 401.56C344.568 401.56 357.005 389.122 357.005 373.78C357.005 358.438 344.568 346 329.225 346C313.883 346 301.445 358.438 301.445 373.78C301.445 389.122 313.883 401.56 329.225 401.56Z" fill="#6C63FF"/>
<path d="M325.435 388.238C324.956 388.238 324.482 388.126 324.053 387.911C323.624 387.697 323.251 387.385 322.963 387.001L315.385 376.896C315.141 376.571 314.964 376.202 314.863 375.809C314.763 375.416 314.74 375.007 314.798 374.605C314.855 374.203 314.991 373.817 315.198 373.468C315.405 373.118 315.678 372.813 316.003 372.57C316.327 372.327 316.697 372.149 317.09 372.049C317.483 371.948 317.892 371.926 318.294 371.983C318.696 372.04 319.082 372.176 319.431 372.383C319.78 372.59 320.085 372.863 320.329 373.188L325.287 379.799L338.022 360.697C338.247 360.359 338.536 360.068 338.873 359.842C339.211 359.616 339.589 359.458 339.987 359.379C340.385 359.299 340.795 359.298 341.194 359.377C341.592 359.456 341.971 359.612 342.309 359.837C342.647 360.063 342.937 360.352 343.163 360.69C343.389 361.027 343.546 361.406 343.625 361.804C343.705 362.202 343.705 362.612 343.626 363.01C343.547 363.409 343.39 363.787 343.164 364.125L328.007 386.861C327.733 387.273 327.364 387.612 326.931 387.851C326.499 388.091 326.015 388.223 325.521 388.237C325.493 388.237 325.464 388.238 325.435 388.238Z" fill="white"/>
<path d="M83.6281 153.709C83.6114 138.677 89.3544 124.21 99.6764 113.282L64.2531 64.5269C62.7949 62.5182 60.921 60.8474 58.759 59.6281C56.597 58.4089 54.1976 57.6699 51.7242 57.4614C49.2509 57.253 46.7617 57.5799 44.4261 58.4201C42.0905 59.2602 39.9634 60.5938 38.1896 62.3301C26.055 74.159 16.4215 88.3059 9.86101 103.931C3.30056 119.555 -0.0529696 136.339 -0.000367198 153.285C-0.000268394 159.684 0.476815 166.075 1.42689 172.403C1.79961 174.905 2.71362 177.296 4.10518 179.408C5.49674 181.521 7.33236 183.304 9.48402 184.634C11.6114 185.951 14.0004 186.788 16.4844 187.086C18.9684 187.385 21.4876 187.138 23.8663 186.362L85.0319 166.488C84.101 162.292 83.6303 158.007 83.6281 153.709V153.709Z" fill="#F2F2F2"/>
<path d="M86.8814 173.027L23.2916 193.689C20.9382 194.45 18.7758 195.707 16.951 197.377C15.1262 199.046 13.6816 201.089 12.715 203.365C11.7485 205.642 11.2826 208.1 11.349 210.572C11.4154 213.045 12.0124 215.474 13.0997 217.695C22.1554 236.067 35.5046 251.987 52.0171 264.106C68.5296 276.226 87.7191 284.188 107.961 287.319C110.426 287.698 112.944 287.539 115.342 286.853C117.74 286.167 119.962 284.971 121.854 283.346C123.744 281.726 125.261 279.715 126.3 277.453C127.34 275.191 127.877 272.731 127.876 270.242V210.849C118.458 208.406 109.789 203.675 102.638 197.077C95.4863 190.479 90.0742 182.218 86.8814 173.027Z" fill="#E6E6E6"/>
<path d="M278.113 210.136C277.039 207.586 275.429 205.298 273.392 203.427C271.355 201.555 268.939 200.144 266.308 199.29L196.955 176.756C192.906 186.277 186.427 194.567 178.166 200.796C169.906 207.025 160.153 210.974 149.886 212.248V284.507C149.887 287.281 150.488 290.021 151.648 292.541C152.807 295.061 154.497 297.3 156.603 299.106C158.708 300.911 161.179 302.24 163.846 303.002C166.514 303.763 169.314 303.939 172.055 303.518C194.588 300.032 215.95 291.169 234.331 277.677C252.713 264.186 267.573 246.465 277.654 226.014C278.866 223.555 279.534 220.865 279.613 218.125C279.693 215.385 279.181 212.66 278.113 210.136Z" fill="#0E2F4E"/>
<path d="M260.882 44.3944C258.907 42.4615 256.539 40.9768 253.939 40.0415C251.339 39.1062 248.568 38.7421 245.815 38.9742C243.062 39.2062 240.391 40.0289 237.984 41.3862C235.577 42.7435 233.491 44.6036 231.868 46.8397L184.453 112.101C189.908 117.569 194.232 124.058 197.179 131.197C200.126 138.336 201.638 145.986 201.628 153.709C201.628 155.146 201.559 156.566 201.458 157.977L276.827 182.466C279.492 183.326 282.312 183.595 285.092 183.254C287.871 182.913 290.543 181.97 292.921 180.491C295.298 179.011 297.325 177.032 298.86 174.689C300.394 172.347 301.399 169.698 301.806 166.927C302.864 159.882 303.395 152.768 303.395 145.644C303.453 126.78 299.72 108.096 292.417 90.7033C285.114 73.3102 274.39 57.5621 260.882 44.3944V44.3944Z" fill="#0DCBFD"/>
<path d="M142.629 94.708C152.78 94.6965 162.76 97.3173 171.596 102.314L200.536 62.4819C202.011 60.4533 203.028 58.1288 203.518 55.6688C204.007 53.2087 203.957 50.6717 203.371 48.2329C202.803 45.8406 201.724 43.6 200.206 41.6656C198.688 39.7312 196.769 38.1493 194.58 37.0288C176.713 27.9419 156.956 23.1914 136.911 23.1622C116.865 23.1331 97.095 27.826 79.2012 36.8608C76.9446 37.9994 74.9625 39.615 73.3923 41.5957C71.8221 43.5763 70.7012 45.8747 70.1074 48.3315C69.5218 50.7533 69.4698 53.2734 69.9549 55.7173C70.44 58.1612 71.4508 60.4704 72.917 62.4848L105.747 107.672C116.2 99.2658 129.215 94.691 142.629 94.708V94.708Z" fill="#E6E6E6"/>
</svg>        </div>
        <div className="md:7/12 lg:w-1/2">
          <h2 className="text-xl font-bold text-gray-900 md:text-2xl dark:text-white">
          Qualität " Made in Austria"
          </h2>
          <p className="my-8 text-gray-600 dark:text-gray-300">
          Unsere umfassenden Dienstleistungen: von der Planung und Installation bis hin zur Wartung und Reparatur, sind darauf ausgerichtet, Ihnen die bestmögliche Lösung für Ihre individuellen Anforderungen zu bieten. Wir sind stolz darauf, hochwertige Produkte und Dienstleistungen anzubieten, die auf Ihre Bedürfnisse zugeschnitten sind.
         </p>
          <div className="divide-y space-y-4 divide-gray-100 dark:divide-gray-800">
            <div className="mt-8 flex gap-4 md:items-center">
              <div className="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 m-auto text-indigo-500 dark:text-indigo-400">
                  <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z" clipRule="evenodd"></path>
                </svg>        
              </div>
              <div className="w-5/6">
                <h3 className="font-semibold text-lg text-gray-700 dark:text-indigo-300">Persönliche Beratung</h3>
                <p className="text-gray-500 dark:text-gray-400">Wir erstellen unverbindlich ein maßgeschneidertes Full-Service-Angebot für Sie.</p>
              </div> 
            </div> 
            <div className="pt-4 flex gap-4 md:items-center">
              <div className="w-12 h-12 flex gap-4 rounded-full bg-teal-100 dark:bg-teal-900/20">  
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 m-auto text-teal-600 dark:text-teal-400">
                  <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
                </svg>                                      
              </div>
              <div className="w-5/6">
                <h3 className="font-semibold text-lg text-gray-700 dark:text-teal-300">Förderantragsabwicklung</h3>
                <p className="text-gray-500 dark:text-gray-400">Wir beraten Sie unverbindlich über die Möglichkeiten im Bereich der Förderung von Wärmepumpen.</p>
              </div> 
            </div> 
          </div>
        </div>
        </div>
        </div>
              {/*       <!-- Component: Four shop  --> */}

      <div>
        

      </div>
{/* <!-- End Four columns even layout --> */}

             {/*<!-- Component: Testimonial carousel --> */}
             <div className="py-20 bg-gray-1">
            <div className="container">
                <div className="wow fadeInUp" data-wow-delay=".2s">
                <div className="mb-12 text-center">
                    <h2 className="mb-4 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
                    <span className="text-primary">Unsere</span> Kunden
                    </h2>
                    <p className="text-base leading-relaxed text-body-color">
                    Wir sind stolz darauf, dass wir seit über 10 Jahren eine große Anzahl an zufriedenen Kunden haben. Wir freuen uns, dass wir Ihnen helfen können, Ihre Apple-Geräte wieder zum Laufen zu bringen.
                    </p>
                </div>
                <CarouselTestimonial/>

                </div>
            </div>
         
            </div>
        
        {/*<!-- End Testimonial carousel --> */}
        <Cookiescomponent/>

      {/*<!-- ort Start --> */}
  
      {/*<!-- ort end  -->
          <section>
        <div className="container px-6 m-auto my-6">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
            <div className="col-span-4">
            <div className="w-full shadow-xl  rounded-lg transition duration-700 ease-in-out border hover:scale-110 hover:border-white">
                  <div
                    className=" bg-white text-center"
                    data-wow-delay=".1s">
                    <div className="items-center justify-center rounded-[14px] bg-transparent mx-auto">
                      <img src="https://source.unsplash.com/random/800x600" alt="plumbing" className="w-full h-32"/>
                    </div>
                    <h4 className="mb-3 text-xl font-bold text-white bg-gradient-to-r from-[#00ceef] to-gray-900">
                    Wasser
                    </h4>
                    <p className="text-body-color mb-2">
                    Sanitär-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Wasser-Installationen
                    </p>
                    <p className="text-body-color mb-2">
                    Küche, Badezimmer, WC
                    </p>
                    <p className="text-body-color mb-2">
                    Warmwasser-Wärmepumpen
                    </p>
                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
       */}
      <Einsatzgebiete/>

      </div>

    );
  }
}


