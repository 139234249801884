import axios from 'axios';
import * as React from 'react';

interface Testimonial {
    author_name: string;
    text: string;
    rating: number; // Assuming this matches your actual API response
    time: string; // Assuming this matches your actual API response
    profile_photo_url: string;
    author_url: string;
    relative_time_description: string;
  }
  

export interface ICarouselTestimonialProps {
}

export interface ICarouselTestimonialState {
    currentSlideIndex: number;
    reviews: Testimonial[];
    intervalId: NodeJS.Timeout | null;

}

export default class CarouselTestimonial extends React.Component<ICarouselTestimonialProps, ICarouselTestimonialState> {
  constructor(props: ICarouselTestimonialProps) {
    super(props);

    this.state = {
        currentSlideIndex: 0,
        reviews: [],
        intervalId: null,

 
  }
  }

  componentDidMount() {
    this.fetchGoogleReviews();
    this.setupAutoplay();

  }

  fetchGoogleReviews = () => {
    axios
      .get(`https://www.mannus-installateur.at/api/GoogleReviewsComponent.php`)
      .then((response) => {
        // Adjust this line according to the actual structure of your response
        this.setState({ reviews: response.data }); // Assuming response.data contains { reviews: Testimonial[] }
      })
      .catch((error) => {
        console.error("Error fetching Google Reviews:", error);
      });
  };

  setupAutoplay = () => {
    const intervalId = setInterval(this.goToNextSlide, 6000);
    this.setState({ intervalId });
  };

  goToNextSlide = () => {
    this.setState(prevState => ({
      currentSlideIndex: (prevState.currentSlideIndex + 1) % prevState.reviews.length,
    }));
  };

  renderStarRating(rating: number) {
    // Assuming 5 as the maximum rating
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <svg
          key={i}
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 ${i <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
          fill="currentColor"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {/* Path for a star */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11.049 2.927c.3-.921 1.653-.921 1.953 0l1.89 5.82h6.127c1.104 0 1.56 1.337.75 2.011l-4.971 3.908 1.861 5.74c.332 1.024-1.024 1.879-1.961 1.3l-5.15-3.396-5.151 3.396c-.936.579-2.293-.276-1.961-1.3l1.861-5.74-4.971-3.908c-.81-.674-.354-2.011.75-2.011h6.127l1.89-5.82z"
          />
        </svg>
      );
    }
    return stars;
  }

  render() {
    const { currentSlideIndex, reviews } = this.state;
    // Ensure we have 3 reviews to display for large screens, repeat the array if needed
    const displayReviews =   [...reviews, ...reviews, ...reviews];
    const currentTestimonial = displayReviews.slice(currentSlideIndex, currentSlideIndex + 3);    
    

    
    return (
        <div className=''>
        {/*<!-- Component: Testimonial carousel --> */}
        {window.innerWidth >550 && (
        <div className="glide-08 relative w-full">
          {/*    <!-- Slides --> */}
          <div data-glide-el="track">
            <ul className="whitespace-no-wrap flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-16 pb-12">
                {currentTestimonial.map((testimonial, index) => (
                      <li
                    key={testimonial.time}
                    className={`glide-08__slide flex-none w-full  flex items-center justify-center lg:w-1/3 shadow-xl bg-white rounded-2xl p-4 m-2 h-96  transform transition-transform duration-300 ease-in-out `}
                    >
                    <div className="flex flex-col items-center gap-4 ">
                        <div className="w-16 h-16 rounded-full overflow-hidden">
                        <img
                            src={testimonial.profile_photo_url}
                            alt={testimonial.author_name}
                        />
                        </div>
                        <h3 className="text-xl font-bold text-center">
                        {testimonial.author_name}
                        </h3>
                        <p className="text-center line-clamp-4">{testimonial.text}</p>
                        <div className="flex items-center justify-center gap-1">
                        {this.renderStarRating(testimonial.rating)}
                        </div>
                        <div className="text-center">
                        <a
                            href={testimonial.author_url}
                            className="text-blue-800 font-bold hover:underline"
                        >
                           mehr lesen
                        </a>
                        </div>
                        <div className="text-center">
                        <p>{testimonial.relative_time_description}</p>
                        </div>

                    </div>
                    </li>
                    
                    
                ))}
            </ul>
          </div>
          {/*    <!-- Indicators --> */}
   
        </div>
        )}

        {/*<!-- End Testimonial carousel --> */}
        {window.innerWidth <= 550 && (
            <div className="glide-08 relative w-full">
                {/*    <!-- Slides --> */}
                <div data-glide-el="track">
                    <ul className="whitespace-no-wrap flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-0 pb-12">
                        {currentTestimonial.map((testimonial, index) => (
                                <li
                                    key={testimonial.time}
                                    className={`glide-08__slide flex-none p-4 shadow-xl bg-white rounded-2xl  m-2 h-96 w-full flex items-center justify-center transform transition-transform duration-300 ease-in-out`}
                                >
                                    <div className="flex flex-col items-center gap-4 ">
                                        <div className="w-16 h-16 rounded-full overflow-hidden">
                                            <img
                                                src={testimonial.profile_photo_url}
                                                alt={testimonial.author_name}
                                            />
                                        </div>
                                        <h3 className="text-xl font-bold text-center">
                                            {testimonial.author_name}
                                        </h3>
                                        <p className="text-center line-clamp-4">{testimonial.text}</p>
                                        <div className="flex items-center justify-center gap-1">
                                            {this.renderStarRating(testimonial.rating)}
                                        </div>
                                        <div className="text-center">
                                            <a
                                                href={testimonial.author_url}
                                                className="text-blue-800 font-bold hover:underline"
                                            >
                                                mehr lesen
                                            </a>
                                        </div>
                                        <div className="text-center">
                                            <p>{testimonial.relative_time_description}</p>
                                        </div>

                                    </div>
                                </li>


                        ))}
                    </ul>
                </div>
                {/*    <!-- Indicators --> */}
            </div>
        )}
        </div>
    );
    }
}