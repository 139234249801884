import * as React from 'react';

export interface ICardImageProps {
    title: string
    isWithimg?:boolean
    img:string;
    
}
export interface ICardImageState {
}

export default class CardImage extends React.Component<ICardImageProps, ICardImageState> {
  constructor(props: ICardImageProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <>
  {this.props.isWithimg?
        <div
        className={`bg-white shadow-md rounded p-1 max-w-sm md:w-full transition duration-500 ease-in-out border w-full  hover:scale-110`}>
          {/*  <!--  Image --> */}
          <figure>
            <img
              src={this.props.img}
              alt={this.props.title}
              className=" rounded-md mx-auto w-full h-64 object-cover"
            />
          </figure>
          {/*  <!-- Body--> */}
          <div className="py-3">
            <header className="">
              <h3 className="lg:text-xl text-[14px] font-bold text-black  text-center">
                {this.props.title}
              </h3>
            </header>
          </div>
        </div>

        :
        <div
        className='shadow-lg shadow-[#000] rounded-md bg-[#000] p-2 max-w-sm md:w-1/3 transition duration-500 ease-in-out border w-full hover:scale-110 hover:border-black'>          {/*  <!-- Body--> */}
          <div className="">
            <header className="">
              <h3 className="text-lg font-bold text-white  text-center">
                {this.props.title}
              </h3>
            </header>
          </div>

        </div>
        }
      </>
    );
  }
}
