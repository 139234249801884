import * as React from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "../Section/ScrollToTop";
import Home from "../Pages/Home";
import Contact from "../Pages/Contact";
import AboutUs from "../Pages/AboutUs";
import Ourprojects from "../Pages/Ourprojects";
import Impressum from "../Pages/Impressum";
import Installateur from "../24Notdienst/Installateur";
import neighborhoods from "../../mockData/neighborhoods.json";
import PlumbingWaterInstallation from "../Pages/PlumbingWaterInstallation/PlumbingWaterInstallation";
import Notdienst from "../Pages/Notdienst/Notdienst";
import Thermenwartung from "../Thermenwartung/Thermenwartung";
import ServicesForm from "../Pages/ServicesForm";

// Define the types for neighborhood keys and details
type NeighborhoodKeys = 
  | "1010-innere-stadt" 
  | "1020-leopoldstadt" 
  | "1030-landstrasse" 
  | "1040-wieden" 
  | "1050-margareten" 
  | "1060-mariahilf" 
  | "1070-neubau" 
  | "1080-josefstadt" 
  | "1090-alsergrund" 
  | "1100-favoriten";

interface NeighborhoodDetails {
  title1: string;
  location: string;
  companyName: string;
  introText: string;
  title2: string;
  serviceText: string;
  title3: string;
  emergencyServiceText: string;
  title4: string;
  finalText: string;
  imageUrl: string;
}

// Define the data with all neighborhood keys and corresponding details
const data: Record<NeighborhoodKeys, NeighborhoodDetails> =  neighborhoods;


interface IMainProps {}
interface IMainState {}

export default class Main extends React.Component<IMainProps, IMainState> {
  public render() {
    return (
      <div className="h-full lg:shadow-2xl bg-white rounded-2xl">
        <ScrollToTop />
        <Routes>
          {/* Static Routes */}
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/ourprojects" element={<Ourprojects />} />
          <Route path="/services" element={<ServicesForm />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/PlumbingWaterInstallation" element={<PlumbingWaterInstallation />} />
          <Route path="/Notdienst" element={<Notdienst />} />
          <Route path="/Thermenwartung" element={<Thermenwartung />} />

          {/* Dynamic Routes for Neighborhoods */}
          {Object.entries(data).map(([key, value]) => (
            <Route
              key={key}
              path={`/neighborhood/${key}`}
              element={
                <Installateur
                  title1={value.title1}
                  location={value.location}
                  companyName={value.companyName}
                  introText={value.introText}
                  title2={value.title2}
                  serviceText={value.serviceText}
                  title3={value.title3}
                  emergencyServiceText={value.emergencyServiceText}
                  title4={value.title4}
                  finalText={value.finalText}
                  imageUrl={value.imageUrl}
                />
              }
            />
          ))}
        </Routes>
      </div>
    );
  }
}
