import React, { Component } from 'react';
import Banner from '../Section/Banner';
import Einsatzgebiete from './Einsatzgebiete';

interface InstallateurProps {
  title1: string;
  location: string;
  companyName: string;
  introText: string;
  title2: string;
  serviceText: string;
  title3: string;
  emergencyServiceText: string;
  title4: string;
  finalText: string;
  imageUrl: string;
}

class Installateur extends Component<InstallateurProps> {
  render() {
    const {
      title1,
      location,
      companyName,
      introText,
      title2,
      serviceText,
      title3,
      emergencyServiceText,
      title4,
      finalText,
      imageUrl,
    } = this.props;

    return (
      <>
           <Banner isHome={false} 
      pageName={location}
      color="violet"
      />
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div
          className="h-64 bg-cover bg-center"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="h-full w-full flex items-center justify-center bg-black bg-opacity-90">
            <h1 className="text-white text-4xl font-bold animate-fade-in-down">
              {title1} <span className="text-yellow-400">{location}</span>
            </h1>
          </div>
        </div>
        <div className="p-8">
          <h2 className="text-3xl font-semibold text-gray-800 animate-fade-in-up">
            Willkommen bei <span className="text-blue-500">{companyName}</span>
          </h2>
          <p className="mt-4 text-gray-600 text-lg animate-fade-in-left">
            {introText}
          </p>
          <h3 className="text-2xl font-semibold text-gray-700 mt-8 animate-fade-in-right">
            {title2}
          </h3>
          <p className="mt-2 text-gray-600 text-lg animate-fade-in-left">
            {serviceText}
          </p>
          <h3 className="text-2xl font-semibold text-gray-700 mt-8 animate-fade-in-right">
            {title3}
          </h3>
          <p className="mt-2 text-gray-600 text-lg animate-fade-in-left">
            {emergencyServiceText}
          </p>
          <h3 className="text-2xl font-semibold text-gray-700 mt-8 animate-fade-in-right">
            {title4}
          </h3>
          <p className="mt-2 text-gray-600 text-lg animate-fade-in-left">
            {finalText}
          </p>
        </div>
      </div>
      <Einsatzgebiete />
      </>
    );
  }
}

export default Installateur;
