import React from 'react';
import { NavLink } from 'react-router-dom';
interface Neighborhood {
    title: string;
    link: string;
  }
const Einsatzgebiete: React.FC = () => {
    const neighborhoods: Neighborhood[] = [
        { title: "1010 Innere Stadt", link: "/neighborhood/1010-innere-stadt" },
        { title: "1020 Leopoldstadt", link: "/neighborhood/1020-leopoldstadt" },
        { title: "1030 Landstraße", link: "/neighborhood/1030-landstrasse" },
        { title: "1040 Wieden", link: "/neighborhood/1040-wieden" },
        { title: "1050 Margareten", link: "/neighborhood/1050-margareten" },
        { title: "1060 Mariahilf", link: "/neighborhood/1060-mariahilf" },
        { title: "1070 Neubau", link: "/neighborhood/1070-neubau" },
        { title: "1080 Josefstadt", link: "/neighborhood/1080-josefstadt" },
        { title: "1090 Alsergrund", link: "/neighborhood/1090-alsergrund" },
        { title: "1100 Favoriten", link: "/neighborhood/1100-favoriten" },
        { title: "1110 Simmering", link: "/neighborhood/1110-simmering" },
        { title: "1120 Meidling", link: "/neighborhood/1120-meidling" },
        { title: "1130 Hietzing", link: "/neighborhood/1130-hietzing" },
        { title: "1140 Penzing", link: "/neighborhood/1140-penzing" },
        { title: "1150 Rudolfsheim-Fünfhaus", link: "/neighborhood/1150-rudolfsheim-fünfhaus" },
        { title: "1160 Ottakring", link: "/neighborhood/1160-ottakring" },
        { title: "1170 Hernals", link: "/neighborhood/1170-hernals" },
        { title: "1180 Währing", link: "/neighborhood/1180-währing" },
        { title: "1190 Döbling", link: "/neighborhood/1190-döbling" },
        { title: "1200 Brigittenau", link: "/neighborhood/1200-brigittenau" },
        { title: "1210 Floridsdorf", link: "/neighborhood/1210-floridsdorf" },
        { title: "1220 Donaustadt", link: "/neighborhood/1220-donaustadt" },
        { title: "1230 Liesing", link: "/neighborhood/1230-liesing" },
      ];

  return (
    <div className="2xl:mx-auto 2xl:container lg:py-16 lg:px-40 xl:px-20 md:py-12 md:px-6 py-9 px-4">
      <h2 className="text-3xl font-medium leading-9 text-gray-700">
        <span className="text-custom-preise">Unsere </span>Einsatzgebiete in
        <span className="text-custom-preise"> Wien </span>&amp;
        <span className="text-custom-preise"> Niederösterreich</span>
      </h2>
      <p className="block antialiased font-sans text-gray-700 text-xl font-medium mt-5 leading-5">
        Alle Wiener Bezirke
      </p>
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        {neighborhoods.map((item) => (
          <div
            key={item.title}
            className="relative flex flex-col bg-clip-border text-gray-700 bg-gray-100 rounded-xl shadow-xl mt-5"
          >
            <NavLink
              to={item.link}
              className="flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-gray-50 hover:bg-opacity-80 focus:bg-blue-gray-50 focus:bg-opacity-80 active:bg-blue-gray-50 active:bg-opacity-80 hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900 outline-none"
            >
              {item.title}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Einsatzgebiete;
