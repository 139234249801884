import * as React from 'react';

export interface IRequestFormProps {}

export interface IRequestFormState {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    message: string;    
    address: string;
    ort: string;
    plz: string;    
    successMsg: boolean;
    errorMsg: string;
}

export default class RequestForm extends React.Component<IRequestFormProps, IRequestFormState> {
  constructor(props: IRequestFormProps) {
    super(props);

    this.state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
        address: '',
        ort: '',
        plz: '',
        successMsg: false,
        errorMsg: '',
      };

    this.handleSendEmail = this.handleSendEmail.bind(this);
  }

  private handleSendEmail(): void {
    const { 
        firstName,
        lastName,
        phoneNumber,
        email,
        message,
        address,
        ort,
        plz,
    } = this.state;

    // Basic client-side validation
    if (!firstName || !lastName || !email || !phoneNumber || !address || !ort || !plz) {
        this.setState({ errorMsg: 'Bitte füllen Sie alle erforderlichen Felder aus.' });
        return;
    }

    // Optionally, add more validation (e.g., email format)

    const data = {
        firstName,
        lastName,
        phoneNumber,
        email,
        message,
        address,
        ort,
        plz,
    };

    fetch('https://www.mannus-installateur.at/api/send_email_contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(async (response) => {
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Server Error: ${errorText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log('Success:', data);
        if (data.success) {
            this.setState({
                successMsg: true,
                errorMsg: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                message: '',
                address: '',
                ort: '',
                plz: '',
            });

            // Scroll to top after successful submission
            window.scrollTo(0, 0);
        } else {
            // Handle application-level errors
            this.setState({ successMsg: false, errorMsg: data.message || 'Ein Fehler ist aufgetreten.' });
            console.error('Error:', data.message);
        }
    })
    .catch((error) => {
        console.error('Fetch Error:', error.message);
        this.setState({ successMsg: false, errorMsg: error.message });
    });
    this.setState({
        errorMsg: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
        address: '',
        ort: '',
        plz: '',
    });
  }

  render() {
    const { successMsg, errorMsg } = this.state;

    return (
      <div>
        <div>
            <h1 className="text-4xl text-center font-bold mt-8 uppercase">
              Kontaktieren Sie uns für strahlende Energie: Wir sind für Sie da!
            </h1>
        </div>
        {successMsg && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative my-4" role="alert">
                <strong className="font-bold">Erfolg!</strong>
                <span className="block sm:inline"> Ihre Nachricht wurde erfolgreich gesendet.</span>
            </div>
        )}
        {errorMsg && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-4" role="alert">
                <strong className="font-bold">Fehler!</strong>
                <span className="block sm:inline"> {errorMsg}</span>
            </div>
        )}
        <form className="w-full max-w-3xl mx-auto mt-8">
            {/* Form Fields */}
            {/* ... (Your existing form fields with onChange handlers) ... */}
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-first-name">
                        Vorname
                    </label>
                    <input
                        onChange={e => this.setState({ firstName: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        type="text"
                        placeholder="Vorname"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-last-name">
                        Nachname
                    </label>
                    <input
                        onChange={e => this.setState({ lastName: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-last-name"
                        type="text"
                        placeholder="Nachname"
                    />
                </div>
            </div>
            {/* ... (Other form fields) ... */}
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-email">
                        E-Mail
                    </label>
                    <input
                        onChange={e => this.setState({ email: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-email"
                        type="email"
                        placeholder="E-Mail"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-phone-number">
                        Telefonnummer
                    </label>
                    <input
                        onChange={e => this.setState({ phoneNumber: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-phone-number"
                        type="text"
                        placeholder="Telefonnummer"
                    />
                </div>
            </div>
            {/* ... (Other form fields) ... */}
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-address">
                        Adresse
                    </label>
                    <input
                        onChange={e => this.setState({ address: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-address"
                        type="text"
                        placeholder="Adresse"
                    />
                </div>
            </div>
            {/* ... (Other form fields) ... */}
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-plz">
                        PLZ
                    </label>
                    <input
                        onChange={e => this.setState({ plz: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-plz"
                        type="text"
                        placeholder="PLZ"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-ort">
                        Ort
                    </label>
                    <input
                        onChange={e => this.setState({ ort: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-ort"
                        type="text"
                        placeholder="Ort"
                    />
                </div>
            </div>
  

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-message">
                        Nachricht
                    </label>
                    <textarea
                        onChange={e => this.setState({ message: e.target.value })}
                        autoComplete='off'
                        className="mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-message"
                        rows={5}
                        placeholder="Ihre Nachricht hier..."
                    ></textarea>
                </div>
            </div>

            <div className="md:flex md:items-center">
                <div className="w-full p-6">
                    <button
                    disabled={
                        !this.state.firstName ||
                        !this.state.lastName ||
                        !this.state.email ||
                        !this.state.phoneNumber ||
                        !this.state.address ||
                        !this.state.ort ||
                        !this.state.plz
                    }
                        onClick={this.handleSendEmail}
                        className="shadow-lg w-full bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg
                        disabled:opacity-50 disabled:cursor-not-allowed"
                        type="button"
                    >
                        Senden 
                    </button>
                </div>
            </div>
        </form>
      </div>
    );
  }
}
