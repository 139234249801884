import * as React from 'react';
import CardImage from '../../Section/CardImage';
import RohrbruchImage from '../../../images/Rohrbruch.jpg';
import ArmaturenImage from '../../../images/Armaturen.jpg';
import Armaturen2Image from '../../../images/Armaturen2.jpg';
import VerstopfteImage from '../../../images/Verstopfte.jpg';
import WasserleitungenImage from '../../../images/Wasserleitungen.jpg';
import WasserhahnImage from '../../../images/Wasserhahn.jpg';
import SpueleeinbauenImage from '../../../images/Spueleeinbauen.jpg';
import SiphonmontierenImage from '../../../images/Siphonmontieren.jpg';


export interface IDienstleistungenProps {
}

export interface IDienstleistungenState {
}

export default class Dienstleistungen extends React.Component<IDienstleistungenProps, IDienstleistungenState> {
  constructor(props: IDienstleistungenProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <section>
        <h4 className="text-3xl font-bold text-center text-gray-900 dark:text-gray-100 my-3">Unsere Dienstleistungen</h4>
        <p className="text-center text-gray-600 dark:text-gray-300">Wir bieten eine breite Palette von Dienstleistungen an, die auf Ihre individuellen Anforderungen zugeschnitten sind.</p>
        <div className="container px-6 m-auto my-6">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
        <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Rohrbruch"
              isWithimg={true}
              img={RohrbruchImage}
      />
        </div>
            <div className="col-span-4 lg:col-span-3">
              <CardImage 
              title="Sanitär & Armaturen"
              isWithimg={true}
              img={ArmaturenImage}
      />
      </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Verstopfte Abflüsse"
              isWithimg={true}
              img={VerstopfteImage}
      />
            </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Wasserleitungen"
              isWithimg={true}
              img={WasserleitungenImage}
      />
            </div>
          </div>
        </div>

        <div className="container px-6 m-auto my-6">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
        <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Wasserhahn"
              isWithimg={true}
              img={WasserhahnImage}
      />
        </div>
            <div className="col-span-4 lg:col-span-3">
              <CardImage 
              title="Spüle einbauen"
              isWithimg={true}
              img={SpueleeinbauenImage}
      />
      </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Armaturen"
              isWithimg={true}
              img={Armaturen2Image}
      />
            </div>
            <div className="col-span-4 lg:col-span-3">
            <CardImage 
              title="Siphon montieren"
              isWithimg={true}
              img={SiphonmontierenImage}
      />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
