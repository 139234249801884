import * as React from "react";
import { NavLink } from "react-router-dom";
import Logo from '../../images/logo-white.png';


export interface IHeaderProps {}

export interface IHeaderState {
  isTop: boolean;
  isNavbarTogglerActive: boolean;
  subMenuActive: boolean;
}

export default class Header extends React.Component<
  IHeaderProps,
  IHeaderState
> {
  constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      isTop: true,
      isNavbarTogglerActive: false,
      subMenuActive: false,
    };
    // Bind methods to the component instance
    this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
    this.handleNavItemClick = this.handleNavItemClick.bind(this);
    this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", this.handleScroll);
    // Add click event listener to close the menu when clicking outside of it
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount() {
    // Remove scroll and click event listeners when the component is about to unmount
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("click", this.handleDocumentClick);
  }
  handleNavbarToggle() {
    this.setState((prevState) => ({
      isNavbarTogglerActive: !prevState.isNavbarTogglerActive,
    }));
  }

  handleNavItemClick() {
    this.setState({
      isNavbarTogglerActive: false,
    });
  }

  handleSubmenuClick(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      subMenuActive: !prevState.subMenuActive,
    }));
  }

  handleScroll() {
    const scrollPosition = window.scrollY;
    this.setState({ isTop: scrollPosition === 0 });
  }

  handleDocumentClick(event: MouseEvent) {
    const navbar = document.getElementById("navbarCollapse");
    const navbarToggler = document.getElementById("navbarToggler");

    // If the click is outside the navbar and the navbar toggler is not clicked
    if (
      navbar &&
      navbarToggler &&
      !navbar.contains(event.target as Node) &&
      !navbarToggler.contains(event.target as Node)
    ) {
      this.setState({
        isNavbarTogglerActive: false,
        subMenuActive: false,
      });
    }
  }

  public render(): JSX.Element {
    const { isTop } = this.state;

    return (
        <div
          className={` ${
            isTop ? " absolute lg:top-0 " : " sticky lg:top-0 "} z-40 bg-transparent w-full transition-all duration-600 ease-in-out`}
          data-wow-duration="5s"
          data-wow-delay="1s"
        >
                  <div className="">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-1 lg:col-span-1">
              <NavLink to="/home">
                <div className="w-60">
                  <button className="py-4 lg:py-0">
                    {isTop ? (
                      <img
                        src={Logo}
                        alt="logo"
                        className=" w-1/4 -mt-3 ml-3 lg:ml-6 lg:w-2/6 lg:mt-0"
                      />
                    ) : (
                      <img
                      src={Logo}
                      alt="logo2"
                      className=" w-0 lg:ml-6 lg:w-2/6 lg:mt-0"
                      />
                    )}
                  </button>
                </div>
              </NavLink></div>
            <div className="col-span-11 lg:col-span-9">
                <div>
                  <button
                    onClick={this.handleNavbarToggle}
                    id="navbarToggler"
                    className="absolute right-4 top-1/3 block -translate-y-1/2 rounded-lg mx-6 p-[6px] ring-primary focus:ring-2 lg:hidden"
                  >
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
                    <span className="relative my-[6px] block h-[2px] w-[30px] bg-black"></span>
                  </button>
                  <nav
                    id="navbarCollapse"
                    className={`absolute right-1 top-full ${
                      this.state.isNavbarTogglerActive ? "" : "hidden"
                    } w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-1 lg:shadow-none xl:px-1`}
                  >
                    <ul className="blcok lg:flex 2xl:ml-20">
                      <li className="group relative">
                        <NavLink to="/home">
                          <button
                            onClick={this.handleNavItemClick}
                            className="flex ml-8 py-2 font-medium lg:text-base  text-dark group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70"
                          >
                            Home
                          </button>
                        </NavLink>
                      </li>
                      <li className="group relative">
                        <NavLink to="/aboutus">
                          <button
                            onClick={this.handleNavItemClick}
                            className="ud-menu-scroll mx-8 flex py-2 font-medium lg:text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-2 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-6"
                          >
                            Über uns
                          </button>
                        </NavLink>
                      </li>
                      <li className="group relative">
                        <NavLink to="/PlumbingWaterInstallation">
                          <button
                            onClick={this.handleNavItemClick}
                            className="ud-menu-scroll mx-8 flex py-2 font-medium lg:text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-2 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-6"
                          >
                            Wasser & Sanitär
                          </button>
                        </NavLink>
                      </li>
                      <li className="group relative">
                        <NavLink to="/Thermenwartung">
                          <button
                            onClick={this.handleNavItemClick}
                            className="ud-menu-scroll mx-8 flex py-2 font-medium lg:text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-2 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-6"
                          >
                            Thermenwartung
                          </button>
                        </NavLink>
                      </li>
               
                      <li className="group relative">
                        <NavLink to="/Notdienst">
                          <button
                            onClick={this.handleNavItemClick}
                            className="ud-menu-scroll mx-8 flex py-2 font-medium lg:text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-2 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-6"
                          >
                            24h Notdienst
                          </button>
                        </NavLink>
                      </li>
                      <li className="group relative">
                        <NavLink to="/contact">
                          <button
                            onClick={this.handleNavItemClick}
                            className="ud-menu-scroll mx-8 flex py-2 font-medium lg:text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-2 lg:inline-flex lg:py-6 lg:px-0 lg:text-black lg:group-hover:text-gray-400 lg:group-hover:opacity-70 xl:ml-6"
                          >
                            Kontakt
                          </button>
                        </NavLink>
                      </li>

                      <li className="group absolute right-6 lg:py-4">
                      <NavLink to="/services">
                      <button
                        onClick={this.handleNavItemClick}
                        className=" rounded-md bg-blue-600 bg-opacity-100 py-2 px-6 lg:text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-60 hover:text-white"
                      >
                        Kostenloses Angebot
                      </button>
                    </NavLink>
                    </li>
                    </ul>
{/*
                    
                    <section className="block lg:hidden xl:hidden mt-14">
                      <div className=" px-6 m-auto text-black lg:text-base bg-gray-100 p-2 ">
                        <div className="grid grid-cols-4 gap-1 md:grid-cols-8 lg:grid-cols-12 ">
                          <div className="col-span-4">
                            <p>Inhaber: Subhi </p>
                          </div>
                          <div className="col-span-4">
                            <p>Geschäftsführer: Fadi Alqazzazee</p>
                          </div>
                          <div className="col-span-4">
                            <p>TEL: 06769212103</p>
                          </div>
                        </div>
                        
                      </div>
                    </section>
*/}
                    
                  </nav>
                  
                </div>


            </div>
          </div>
        </div>

            

        </div>
    );
  }
}
