import * as React from 'react';
import Banner from '../Section/Banner';

export interface IServicesFormProps {}

export interface IServicesFormState {
    note: string;
    name: string;
    lastName: string;
    phone: string;
    email: string;
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
    Leistung: string;
    Jahresverbrauch: string;
    succesMsg: boolean;
    photo1: File | null;
    photo2: File | null;
    imagePath: string | null; // Store the base64 string or null if not available
    imagePath2: string | null; // Store the base64 string or null if not available
    errorMsg?: string;
}

export default class ServicesForm extends React.Component<IServicesFormProps, IServicesFormState> {
    constructor(props: IServicesFormProps) {
        super(props);

        this.state = {
            note: '',
            name: '',
            lastName: '',
            phone: '',
            email: '',
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            Leistung: '',
            Jahresverbrauch: '',
            succesMsg: false,
            photo1: null,
            photo2: null,
            imagePath: null,
            imagePath2: null,
            errorMsg: '',
        };
        this.handleSendEmail = this.handleSendEmail.bind(this);
    }

    private handleSendEmail(): void {
        const {
            note, name, lastName, phone, email, street, houseNumber, zip, city,
            Leistung, Jahresverbrauch, imagePath, imagePath2
        } = this.state;

        const data = {
            note,
            name,
            lastName,
            phone,
            email,
            street,
            houseNumber,
            zip,
            city,
            Leistung,
            Jahresverbrauch,
            imagePath,
            imagePath2,
        };


        fetch('https://www.mannus-installateur.at/api/send_email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                // if success
                    this.setState({
                        name: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        street: '',
                        houseNumber: '',
                        zip: '',
                        city: '',
                        Leistung: '',
                        Jahresverbrauch: '',
                        note: '',
                        imagePath: null,
                        imagePath2: null,
                        succesMsg: true, // Set success message state
                        errorMsg: '', // Reset error message state
                    });

                    window.scrollTo(0, 0); // Scroll to top after form reset
                
            })
            .catch((error) => {
                console.error('Error:', error);
                // Optionally, you can set an error message state here to notify the user
            });
        this.setState({
            name: '',
            lastName: '',
            phone: '',
            email: '',
            street: '',
            houseNumber: '',
            zip: '',
            city: '',
            Leistung: '',
            Jahresverbrauch: '',
            note: '',
            imagePath: null,
            imagePath2: null,
  
        });
            window.scrollTo(0, 0); // Scroll to top after form reset

    }

    // Function to convert file to Base64
    getBase64 = (file: File): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => {
                console.error('Error converting file to Base64:', error);
                reject(error);
            };
        });
    };

    // Handle file input changes
    handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList | null;
        if (files && files[0]) {
            try {
                const base64String = await this.getBase64(files[0]);
                this.setState({ imagePath: base64String });
            } catch (error) {
                console.error('Error processing the file', error);
            }
        }
    };

    handlePhoto2Change = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList | null;
        if (files && files[0]) {
            try {
                const base64String = await this.getBase64(files[0]);
                this.setState({ imagePath2: base64String });
            } catch (error) {
                console.error('Error processing the file', error);
            }
        }
    };

    public render() {
        const { succesMsg, errorMsg } = this.state;
        return (
            <div>
                <Banner isHome={false} pageName="Services" color="violet" />
                <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4">
                    <div className="my-3">
                        <p className="text-center text-2xl font-bold">Adresse und Info</p>
                        <p className="text-center text-lg font-bold">
                            Geben Sie die Adresse und Info
                        </p>
                        <p className="text-center text-lg font-bold">
                            und wählen Sie die Leistung aus
                        </p>
                    </div>
                    {succesMsg && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative my-4" role="alert">
                <strong className="font-bold">Erfolg!</strong>
                <span className="block sm:inline"> Ihre Nachricht wurde erfolgreich gesendet.</span>
            </div>
        )}
        {errorMsg && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-4" role="alert">
                <strong className="font-bold">Fehler!</strong>
                <span className="block sm:inline"> {errorMsg}</span>
            </div>
        )}
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ name: e.target.value })}
                            placeholder="Name *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ lastName: e.target.value })}
                            placeholder="Nachname *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ phone: e.target.value })}
                            placeholder="Telefonnummer *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            onChange={(e) => this.setState({ email: e.target.value })}
                            placeholder="Email *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ street: e.target.value })}
                            placeholder="Straße *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ houseNumber: e.target.value })}
                            placeholder="Hausnummer *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ zip: e.target.value })}
                            placeholder="PLZ *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ city: e.target.value })}
                            placeholder="Ort *"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <select
                            onChange={(e) => this.setState({ Leistung: e.target.value })}
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        >
                            <option value="">Leistung *</option>
                            <option value="Wasser & Sanitär">Wasser & Sanitär</option>
                            <option value="Thermenwartung">Thermenwartung</option>
                            <option value="Thermentausch">Thermentausch</option>
                            <option value="Notdienst24">Notdienst 24</option>
                        </select>
                    </div>
                    <div>
                        <input
                            type="text"
                            onChange={(e) => this.setState({ Jahresverbrauch: e.target.value })}
                            placeholder="Gerät Baujahr"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                        />
                    </div>
                    <div>
                        <textarea
                            onChange={(e) => this.setState({ note: e.target.value })}
                            placeholder="Notiz"
                            className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none h-40"
                        />
                    </div>
                    <div className="max-w-xs mx-auto">
                        <div className="mb-4">
                            <label htmlFor="photo1" className="block text-gray-700 font-bold mb-2">
                                Bild * :
                            </label>
                            <input
                                type="file"
                                id="photo1"
                                onChange={this.handleFileChange}
                                accept="image/*"
                                className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="photo2" className="block text-gray-700 font-bold mb-2">
                                Bild:
                            </label>
                            <input
                                type="file"
                                id="photo2"
                                onChange={this.handlePhoto2Change}
                                accept="image/*"
                                className="w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>
                    <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
                        <button
                            disabled={
                                this.state.name === '' ||
                                this.state.lastName === '' ||
                                this.state.phone === '' ||
                                this.state.email === '' ||
                                this.state.street === '' ||
                                this.state.houseNumber === '' ||
                                this.state.zip === '' ||
                                this.state.city === '' ||
                                this.state.Leistung === '' ||
                                this.state.imagePath === null
                            }
                            onClick={() => {
                                this.handleSendEmail();
                            }}
                            className="bg-green-500 text-white font-semibold hover:bg-green-600 py-2 px-4 hover:border-transparent rounded w-1/2 mx-auto my-3
                            disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Senden
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
