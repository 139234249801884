import * as React from 'react';
import Banner from '../../Section/Banner';
import FeaturesTextImg from '../../Section/Features/FeaturesTextImg';
import FeatureSectionColorDesign from './FeatureSectionColorDesign';
import Dienstleistungen from './Dienstleistungen';
import Statistical from '../../Section/Statistical';

export interface IPlumbingWaterInstallationProps {
}

export interface IPlumbingWaterInstallationState {
}

export default class PlumbingWaterInstallation extends React.Component<IPlumbingWaterInstallationProps, IPlumbingWaterInstallationState> {
  constructor(props: IPlumbingWaterInstallationProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
    <Banner isHome={false} pageName="Sanitär Wasserinstallation" color="white"/>
    <FeatureSectionColorDesign title='Unsere exklusiven Features' feature={[
    {
        "title": "Individuelle Lösungen",
        "description": "Bei  Mannus bieten wir maßgeschneiderte Lösungen für Ihre Sanitär- und Entwässerungsarbeiten. Ob komplexe Abwassersysteme, Hausinstallationen oder spezielle Installationen – wir passen unsere Dienstleistungen genau an Ihre Bedürfnisse an."
    },
    {
        "title": "Hervorragende Leistung",
        "description": "Unsere Sanitär- und Entwässerungssysteme sind bekannt für ihre hohe Effizienz und Langlebigkeit. Durch den Einsatz modernster Technik und fachkundiger Handwerkskunst sorgen wir dafür, dass Ihre Anlagen optimal funktionieren und Wasserverschwendung minimiert wird."
    },
    {
        "title": "Maximale Sicherheit",
        "description": "Bei  Mannus steht Sicherheit an erster Stelle. Wir stellen sicher, dass alle Sanitär- und Entwässerungsinstallationen den höchsten Sicherheitsstandards entsprechen, damit Sie sich auf eine zuverlässige und sichere Infrastruktur verlassen können."
    },
    {
        "title": "Umfassender Support",
        "description": "Unser Kundenservice begleitet Sie von der Beratung über die Installation bis hin zur Wartung.  Mannus bietet Ihnen einen Rundum-Service, der keine Fragen offenlässt und stets für Sie erreichbar ist."
    },
    {
        "title": "Zukunftssichere Skalierbarkeit",
        "description": "Unsere Sanitär- und Entwässerungsinstallationen sind so konzipiert, dass sie mit Ihnen wachsen können.  Mannus bietet skalierbare Lösungen, die sich leicht an neue Anforderungen anpassen lassen, sei es im privaten oder gewerblichen Bereich."
    },
    {
        "title": "Zuverlässigkeit",
        "description": "Verlassen Sie sich auf die Beständigkeit unserer Arbeit.  Mannus steht für Zuverlässigkeit und Qualität, die sich in jeder Sanitär- und Entwässerungsinstallation widerspiegeln. Wir lassen Sie auch in Notfällen nicht im Stich."
    }
]
}
/>
      <Dienstleistungen/>
      <div className='mt-6'>
      <Statistical/>

      </div>
      </div>
    );
  }
}
/**
 *     <FeaturesTextImg 
  title="Unsere Exklusiven Merkmale" 
  description="Entdecken Sie eine Welt voller Möglichkeiten mit unseren exklusiven Funktionen. Erfahren Sie, wie unsere einzigartigen Angebote Ihre Reise verändern und Ihnen helfen können, mehr zu erreichen."
  title1="Individuelle Anpassung"
  description1="Bei  Mannus steht Ihre Zufriedenheit an erster Stelle. Wir bieten maßgeschneiderte Lösungen, die genau auf Ihre Bedürfnisse abgestimmt sind. Egal ob es sich um die Installation von Heizungssystemen, Wasserleitungen oder Sanitäranlagen handelt – wir passen unsere Dienstleistungen genau Ihren Anforderungen an."
  title2="Leistungsstärke"
  description2="Unsere Arbeit zeichnet sich durch höchste Effizienz und Langlebigkeit aus. Mit modernster Technologie und präzisem Handwerk garantieren wir, dass alle Installationen den höchsten Standards entsprechen und Ihnen über Jahre hinweg zuverlässige Leistung bieten."
  img1="https://readymadeui.com/contact.webp"
  img2="https://readymadeui.com/contact.webp"
/>
 */