import React from "react";
import LogoImage from '../../images/logo.png';

type ServiceCardProps = {
  title: string;
  description: string;
  imageSrc: any;
  company: string;
};

class ServiceCard extends React.Component<ServiceCardProps> {
  
  render() {
    return (
<section>
  <div className="container px-6 m-auto">
    <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
      <div className="col-span-4 lg:col-span-6">
        <div className="max-w-2xl rounded overflow-hidden shadow-2xl bg-white m-4">
          <img className="w-1/3 m-auto" src={this.props.imageSrc} alt={this.props.title} />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-center">{this.props.title}</div>
            <p className="text-gray-700 text-base">
              {this.props.description}
            </p>
          </div>
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
              {this.props.company}
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-4 lg:col-span-6">
        <ul className="divide-y divide-slate-100">
          <li className="flex items-start gap-4 px-4 py-3">
            <div className="flex items-center text-emerald-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
              </svg>
            </div>
            <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
              <p className="w-full text-sm truncate text-slate-500">
                Kostenersparnis: Mit unseren energieeffizienten Installationen senken Sie Ihre Energiekosten und sparen langfristig Geld. Unsere Lösungen sind darauf ausgelegt, Ihren Energieverbrauch zu optimieren und Ihre Kosten zu minimieren.
              </p>
            </div>
          </li>
          <li className="flex items-start gap-4 px-4 py-3">
            <div className="flex items-center text-emerald-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="User icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
              <p className="w-full text-sm truncate text-slate-500">
                Umweltbewusstsein: Mannus setzt auf nachhaltige Lösungen, die den CO2-Ausstoß verringern. Unsere umweltfreundlichen Installationen tragen aktiv zum Klimaschutz bei und schonen die natürlichen Ressourcen.
              </p>
            </div>
          </li>
          <li className="flex items-start gap-4 px-4 py-3">
            <div className="flex items-center text-emerald-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Analytics icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
              </svg>
            </div>
            <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
              <p className="w-full text-sm truncate text-slate-500">
                Geringe Wartungskosten: Unsere Installationen sind nicht nur hochwertig, sondern auch pflegeleicht. Mannus verwendet langlebige Materialien, die nur minimale Wartung erfordern und Ihnen langfristig Kosten sparen.
              </p>
            </div>
          </li>
          <li className="flex items-start gap-4 px-4 py-3">
            <div className="flex items-center text-emerald-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Cloud icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
              </svg>
            </div>
            <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
              <p className="w-full text-sm truncate text-slate-500">
                Energieunabhängigkeit: Mit unseren modernen Installationen, wie etwa Solar- oder Heizsystemen, machen Sie sich unabhängiger von externen Energieanbietern und sichern sich eine konstante Energieversorgung, selbst bei Netzstörungen.
              </p>
            </div>
          </li>
          <li className="flex items-start gap-4 px-4 py-3">
            <div className="flex items-center text-emerald-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" aria-label="Dashboard icon" role="graphics-symbol">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.737c.32.448.27 1.061-.12 1.45l-.773.773a1.125 1.125 0 01-1.45.12l-.737-.527c-.35-.25-.807-.272-1.205-.107-.397.165-.71.505-.78.93l-.15.893c-.09.543-.559.94-1.11.94h-1.093c-.55 0-1.02-.397-1.11-.94l-.149-.893c-.07-.425-.383-.765-.78-.93a1.125 1.125 0 00-1.205.107l-.737.527a1.125 1.125 0 01-1.45-.12l-.773-.773a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.272-.807.107-1.204-.165-.398-.505-.71-.93-.78l-.893-.15c-.543-.09-.94-.56-.94-1.11v-1.094c0-.55.397-1.02.94-1.11l.893-.149c.425-.07.765-.383.93-.78.165-.397.143-.854-.107-1.204l-.527-.737a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.205.107.397-.165.71-.505.78-.93l.149-.894zM12 15.375A3.375 3.375 0 1012 8.625a3.375 3.375 0 000 6.75z" />
              </svg>
            </div>
            <div className="flex flex-col gap-0 min-h-[2rem] items-start justify-center w-full min-w-0">
              <p className="w-full text-sm truncate text-slate-500">
                Zuverlässiger Service: Unsere erfahrenen Installateure sorgen für eine fachgerechte Installation und bieten Ihnen einen umfassenden Service. Mit Mannus haben Sie einen zuverlässigen Partner an Ihrer Seite.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

    );
  }
}

type ServicesSectionProps = {
  services: ServiceCardProps[];
};

type ServicesSectionState = {
  currentIndex: number;
};

class ServicesSection extends React.Component<ServicesSectionProps, ServicesSectionState> {
  intervalId?: number;
  state = {
    currentIndex: 0,
  };

  componentDidMount() {
    this.startSlideshow();
  }

  componentWillUnmount() {
    this.stopSlideshow();
  }

  startSlideshow = () => {
    this.intervalId = window.setInterval(() => {
      this.setState(prevState => ({
        currentIndex: (prevState.currentIndex + 1) % this.props.services.length,
      }));
    }, 3000); // Slide every 3 seconds
  };

  stopSlideshow = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  render() {
    const { currentIndex } = this.state;
    const { services } = this.props;
    const service = services[currentIndex];
console.log(service.imageSrc);
    return (
      <div className="flex flex-wrap justify-center items-center bg-white">
        <ServiceCard
          key={service.title}
          title={service.title}
          description={service.description}
          imageSrc={LogoImage}
          company={service.company}
        />
      </div>
    );
  }
}

export default ServicesSection;




